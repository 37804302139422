/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link as RouteLink } from 'gatsby'
import { Box, Heading, Container, Text, Flex, Grid, Link } from '@theme-ui/components'
import { useState } from 'react'
import { Card, CardTitle, CardActions, CardIntro, CardSubtitle } from '../organisms/card';

import like from '../../images/like-match.svg';
import chats from '../../images/chats.svg';
import star from '../../images/star.svg'
import circ from '../../images/cd.svg';

import { useFirebase, FirebaseContext } from 'gatsby-plugin-firebase'
import { app } from 'firebase';
import { QueryDocumentSnapshot } from '@google-cloud/firestore';

export const Category = (props: any) => {
  const { id } = props
  const [walls, sw] = useState<firebase.firestore.QueryDocumentSnapshot[]>([])

  useFirebase((firebase: app.App) => {
    firebase
      .firestore()
      .collection("wallpapers")
      .where("category", '==', id)
      .orderBy("createdAt", "desc")
      .limit(25)
      .get()
      .then((data) => {
        console.log(data)
        sw(data.docs as any)
      })
  }, []);

  return (
    <Box py={3}>
      <Container>
        <Box mb={2}>
          <Text>Category</Text>
        </Box>

        <Heading>{id}</Heading>

        <Grid sx={{ py: 4 }} columns={[1, 2, 3]}>
          {walls.map((w) => {
            const {

              category,
              name,
              description,
              images
            } = w.data()!
            return <Box key={w.id}>
              <Card>
                <div>
                  <Link as={RouteLink} to={`/wallpapers/${w.id}`}>
                    <img src={images?.thumb?.url} height="500px" style={{ objectFit: 'cover', width: '100%', borderRadius: '4px 4px 0 0' }} />
                  </Link>
                </div>
                <CardTitle>
                  {name}
                </CardTitle>
                <CardSubtitle>
                  {description}
                </CardSubtitle>
                <CardActions>
                  <Link as={RouteLink} to={`/wallpapers/${w.id}`}>
                    <img width="24" height="24" src={like} sx={{ marginRight: 2 }} />
                  </Link>
                  <Link as={RouteLink} to={`/wallpapers/${w.id}`}>
                    <img width="24" height="24" src={star} sx={{ marginRight: 2 }} />
                  </Link>
                  <Link as={RouteLink} to={`/wallpapers/${w.id}`} >
                    <img width="24" height="24" src={chats} sx={{ marginRight: 2 }} />
                  </Link>
                </CardActions>
              </Card>
            </Box>
          })}
        </Grid>
      </Container>
    </Box>

  )
}