/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Box, Heading, Container } from '@theme-ui/components'

export const Categories = (props: any) => {
  return (
    <Container>
      <Heading>All</Heading>
    </Container>
  )
}