/** @jsx jsx */
import { jsx } from 'theme-ui'

import Image from "../components/image"
import { SEO } from "../components/seo"

import { Heading, Text, Message, Grid, Box, Container, Flex } from '@theme-ui/components'

import { Router } from '@reach/router'
import { Categories } from '../components/templates/categories'
import { Category } from '../components/templates/category'
import { Subscribe } from '../containers/subscribe'
import { Fragment } from 'react'

const CategoryPage = (props: any) => {
  return (
    <Fragment>
      <Flex sx={{ flexDirection: 'column', height: '100%' }}>
        <Box sx={{ flex: 1 }}>
          <Router>
            <Categories path="/categories" />
            <Category path="/categories/:id" />
          </Router>
        </Box>

      </Flex>
    </Fragment>
  )
}

export default CategoryPage